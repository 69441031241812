<template>
  <v-card>
    <!-- TITLE -->
    <v-card-title class="primary">
      <span class="headline">change profile</span>
    </v-card-title>

    <!-- INPUTS -->
    <v-card-text class="pt-5 pb-0">
      <v-container>
        <v-progress-linear
          v-if="formLoading"
          color="primary"
          indeterminate
          rounded
          height="6"
        ></v-progress-linear>
        <div v-else>
          <v-text-field
            dense
            outlined
            label=" Name"
            v-model="adminInfo.name"
            :error-messages="errors.name"
          ></v-text-field>
          <v-text-field
            dense
            outlined
            dir="ltr"
            label="Email"
            v-model="adminInfo.email"
            :error-messages="errors.email"
          ></v-text-field>
          <v-text-field
            dense
            outlined
            dir="ltr"
            label="Phone"
            v-model="adminInfo.phone"
            :error-messages="errors.phone"
          ></v-text-field>
          <v-text-field
            dense
            outlined
            type="password"
            label="Current Password"
            v-model="adminInfo.current_password"
            :error-messages="errors.current_password"
          ></v-text-field>
          <v-text-field
            dense
            outlined
            type="password"
            label="Password"
            v-model="adminInfo.password"
            :error-messages="errors.password"
          ></v-text-field>
        </div>
      </v-container>
    </v-card-text>

    <!-- ACTIONS -->
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        :loading="formLoading"
        color="secondary"
        elevation="1"
        dark
        @click="updateProfile(adminInfo)"
        >حفظ</v-btn
      >
    </v-card-actions>
  </v-card>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "profile",
  data: function () {
    return {
      form: {},
      errors: {},
      formLoading: false,
    };
  },

  methods: {
    ...mapActions("admins", ["fetchMyProfile", "updateProfile"]),
    updateProfile(adminInfo) {
      this.formLoading = true;

      this.$store
        .dispatch("admins/updateProfile", { adminInfo })
        .then((response) => {
          this.$bus.$emit("showSnackbar", {
            text: "تم تعديل بياناتك بنجاح",
            color: "success",
          });
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
          this.$bus.$emit("showSnackbar", {
            text: "خطا اثناء تعديل بياناتك",
            color: "accent",
          });
        })
        .finally(() => {
          this.formLoading = false;
        });
    },
  },
  computed: mapGetters("admins", ["adminInfo"]),
  created() {
    this.fetchMyProfile();
  },
};
</script>
